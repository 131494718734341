<template>
  <div id="home">
    <center>
      <div
        class="pa-10 section"
        id="p1"
        style="max-width: 1000px; text-align: left; height: 100vh"
      >
        <img data-aos="zoom-in" src="@/assets/logo.png" height="100" alt="" />
        <br />
        <code data-aos="zoom-in">Welcome, I'm</code>
        <div style="line-height: 80px">
          <h1 data-aos="zoom-in" style="font-size: 72px">
            Ahmad Faris <i style="font-size: 20px">(AhmaDev)</i>
          </h1>
          <h1
            data-aos="zoom-in"
            class="primary--text h1Primary"
            style="font-size: 72px"
          >
            I build web and mobile apps.
          </h1>
        </div>
        <br />
        <p data-aos="zoom-in">
          I'm a software developer based in <b>Baghdad, Iraq</b>, specializing
          in building exceptional websites and mobile applications, and
          everything in between.
        </p>
        <br />
        <br />
        <v-btn
          href="https://wa.me/+9647829993555"
          rounded
          outlined
          color="success"
        >
          <v-icon left> mdi-whatsapp </v-icon>
          WhatsApp
        </v-btn>
        <br /><br /><br />
        <br /><br /><br />
        <center>
          <v-btn @click="$vuetify.goTo('#p2')" plain>
            <v-icon left>mdi-chevron-down</v-icon>
            continue
          </v-btn>
        </center>
      </div>
      <v-divider></v-divider>
      <div
        class="pa-10 section"
        id="p2"
        style="max-width: 1000px; text-align: left"
      >
        <h1 data-aos="fade-up" class="primary--text">About me</h1>
        <v-row>
          <v-col cols="12" md="6">
            <span data-aos="fade-up">
              Hello! I'm Ahmad, a Software Developer based in Baghdad, Iraq. I
              enjoy creating beautiful and reliable applications for internet
              and phones. My goal is to always build scalable products and
              performant experiences. Here are a few technologies I've been
              working with recently:
            </span>
            <br />
            <br />
            <div data-aos="fade-up" class="technologies">
              <v-chip outlined color="warning">Javascript</v-chip>
              <v-chip outlined color="success">Vue</v-chip>
              <v-chip outlined color="error">Angular</v-chip>
              <v-chip outlined color="primary">Flutter</v-chip>
              <v-chip outlined color="primary">Ionic</v-chip>
              <v-chip outlined color="grey">Electron JS</v-chip>
              <v-chip outlined color="success">Node.js</v-chip>
              <v-chip outlined color="orange">MySQL</v-chip>
              <v-chip outlined color="white">Express</v-chip>
              <v-chip outlined color="orange lighten-2">Firebase</v-chip>
            </div>
          </v-col>
          <v-col cols="12" md="6">
            <center>
              <v-avatar data-aos="zoom-in" size="200">
                <v-img src="@/assets/me.jpeg"></v-img>
              </v-avatar>
            </center>
          </v-col>
        </v-row>
      </div>
      <v-divider></v-divider>
      <div
        data-aos="fade-up"
        id="p3"
        style="max-width: 1000px; text-align: left"
      >
        <h1 class="primary--text pa-10">My clients</h1>
        <br />
        <v-row>
          <v-col>
            <center>
              <img src="@/assets/DucLogo.png" height="80px" alt="" />
              <h4>Dijlah University</h4>
            </center>
          </v-col>
          <v-col>
            <center>
              <img src="@/assets/Activia-logo.png" height="80px" alt="" />
              <h4>Acitivia Iraq</h4>
            </center>
          </v-col>
          <v-col>
            <center>
              <img
                src="@/assets/oveto.png"
                style="border-radius: 20px"
                height="80px"
                alt=""
              />
              <h4>Oveto CRM</h4>
            </center>
          </v-col>
          <v-col>
            <center>
              <img src="@/assets/devpoint.png" height="80px" alt="" />
              <h4>Dev point</h4>
            </center>
          </v-col>
          <v-col>
            <center>
              <img
                src="@/assets/nooor.png"
                style="border-radius: 20px"
                height="80px"
                alt=""
              />
              <h4>Nooor Platform</h4>
            </center>
          </v-col>
          <v-col>
            <center>
              <img
                src="@/assets/mfkLogo.png"
                style="border-radius: 20px"
                height="80px"
                alt=""
              />
              <h4>MFK Company LTD.</h4>
            </center>
          </v-col>
        </v-row>
      </div>
      <br /><br />
      <v-divider></v-divider>
      <div
        class="pa-10 section"
        id="p2"
        style="max-width: 1000px; text-align: center"
        data-aos="fade-up"
      >
        <h1 style="font-size: 42px" class="primary--text h1Primary">
          Get In Touch
        </h1>
        <p>
          My inbox is always open.
          <br />
          Whether you have a question or just want to say hi<br />I'll try my
          best to get back to you!
        </p>
        <br />
        <v-btn
          href="https://wa.me/+9647829993555"
          rounded
          outlined
          color="success"
        >
          <v-icon left> mdi-whatsapp </v-icon>
          WhatsApp
        </v-btn>
        <br />
        <br />
        <v-btn href="https://www.facebook.com/ahmadt3ch" target="_BLANK" icon>
          <v-icon>mdi-facebook</v-icon>
        </v-btn>
        <v-btn href="https://www.twitter.com/_ahmadev" target="_BLANK" icon>
          <v-icon>mdi-twitter</v-icon>
        </v-btn>
      </div>
    </center>
  </div>
</template>

<script>
export default {
  name: "Home",

  components: {},
  data: () => ({
    currentPage: 1,
    maxPages: 3,
  }),
  methods: {
    navigate(to) {
      if (to == "next" && this.currentPage < this.maxPages) {
        this.currentPage++;
        this.$vuetify.goTo("#p" + this.currentPage);
      }
      if (to == "prev" && this.currentPage != 1) {
        this.currentPage--;
        this.$vuetify.goTo("#p" + this.currentPage);
      }
    },
  },
};
</script>

<style scoped>
html,
body {
  overflow: hidden;
  height: 100vh;
  touch-action: none !important;
}

.technologies .v-chip {
  margin-right: 10px;
  margin-bottom: 10px;
}
.section {
  height: 70vh;
  display: table-cell;
  vertical-align: middle;
}

.h1Primary {
  background: -webkit-linear-gradient(-80deg, #0693e3, #f8f550);
  -webkit-background-clip: text;
  background-clip: text;
  -webkit-text-fill-color: transparent;
}
</style>
