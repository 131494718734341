<template>
  <v-app :style="{ background: $vuetify.theme.themes[theme].background }">
    <v-app-bar
      app
      color="#120E2670"
      style="backdrop-filter: blur(10px)"
      dark
      fixed
      elevation="0"
    >
      <v-spacer></v-spacer>

      <v-btn href="https://github.com/ahmadev" target="_blank" text>
        <span class="mr-2">Github</span>
        <v-icon>mdi-github</v-icon>
      </v-btn>
    </v-app-bar>
    <v-navigation-drawer
      class="d-none d-lg-flex d-xl-none"
      fixed
      mini-variant
      color="background"
    >
      <template v-slot:append>
        <v-list shaped>
          <v-list-item
            href="https://www.facebook.com/ahmadt3ch"
            target="_blank"
          >
            <v-icon>mdi-facebook</v-icon>
          </v-list-item>
          <v-list-item href="https://www.twitter.com/_ahmadev" target="_blank">
            <v-icon>mdi-twitter</v-icon>
          </v-list-item>
        </v-list>
      </template>
    </v-navigation-drawer>
    <v-main>
      <router-view />
    </v-main>
  </v-app>
</template>

<script>
export default {
  name: "App",

  data: () => ({
    //
  }),
  computed: {
    theme() {
      return this.$vuetify.theme.dark ? "dark" : "light";
    },
  },
};
</script>

<style>
html {
  background-color: var(--v-background-base);
}
@import url("https://fonts.googleapis.com/css2?family=Fredoka:wght@300&display=swap");
* {
  font-family: "Fredoka", sans-serif;
}

.v-navigation-drawer__border {
  background-color: transparent !important;
}
</style>